import React from "react";
import { TailSpin } from "react-loader-spinner";

function Loading({ size }: Props) {
  let spinnerSize: string | number = 50;
  let width = "50px";
  let height = "50px";
  let backgroundColor = "white";
  if (size === "small") {
    spinnerSize = 50;
    width = "50px";
    height = "50px";
    backgroundColor = "transparent";
  } else if (size === "medium") {
    spinnerSize = 100;
    width = "100px";
    height = "100px";
    backgroundColor = "transparent";
  } else if (size === "large") {
    spinnerSize = "50%";
    width = "100%";
    height = "100%";
    backgroundColor = "white";
  }

  return (
    <TailSpin
      wrapperStyle={{
        backgroundColor,
        width,
        height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      color="#007AFF"
      height={spinnerSize}
      width={spinnerSize}
    />
  );
}

// types
type Props = {
  size: "small" | "medium" | "large";
};

export default Loading;
