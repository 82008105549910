import React, { createContext, useState } from "react";
import { SettingParamDomainEnum } from "../../api/generated/v1.0/app";
import { UserVO } from "../../api/generated/v1.0/open";
import { SettingKeys, Settings, SettingValues } from "../../constants/settings";

export const INITIAL_TEMPS: Pick<GlobalState, "tempAuth" | "tempEmail"> = {
  tempAuth: undefined,
  tempEmail: undefined,
};

const getInitialSettingsValue = (
  domain: SettingParamDomainEnum,
): SettingValues => ({
  id: 0,
  value: "",
  lastModifiedAt: 0,
  valueDataType: "string",
  domain,
});

export const INITIAL_SETTINGS: Settings = {
  companyName: getInitialSettingsValue("COMPANY"),
  invoiceLimit: getInitialSettingsValue("INVOICE"),
  userManual: getInitialSettingsValue("MANUAL"),
  adminManual: getInitialSettingsValue("MANUAL"),
};

export const INITIAL_GLOBAL: GlobalState = {
  ...INITIAL_TEMPS,
  settings: INITIAL_SETTINGS,
  hasFetchedRefreshToken: true,
};

export const GlobalContext = createContext<{
  globalState: GlobalState;
  setGlobalState: (nState: Partial<GlobalState>) => void;
}>({
  globalState: INITIAL_GLOBAL,
  setGlobalState: (nState: Partial<GlobalState>) => {},
});

function GlobalProvider({ children }: React.PropsWithChildren<unknown>) {
  const [state, setState] = useState<GlobalState>(INITIAL_GLOBAL);

  const setGlobalState = (nState: Partial<GlobalState>) => {
    console.log("setting global state");
    console.log(nState);
    setState({ ...state, ...nState });
  };

  return (
    <GlobalContext.Provider value={{ globalState: state, setGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalProvider;

// Types
type GlobalState = {
  tempAuth?: {
    userId: string;
    password: string;
  };
  tempEmail?: string;
  settings: Settings;
  hasFetchedRefreshToken: boolean;
};
