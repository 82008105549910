import { QueryClient, QueryClientProvider } from "react-query";
import React, { Link, BrowserRouter } from "react-router-dom";
import Router from "./Router";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import AuthProvider from "./components/providers/AuthProvider";
import AxiosProvider from "./components/providers/AxiosProvider";
import GlobalProvider from "./components/providers/GlobalProvider";

const queryClient = new QueryClient();

function App() {
  return (
    <GlobalProvider>
      <BrowserRouter>
        <AuthProvider>
          <AxiosProvider>
            <QueryClientProvider client={queryClient}>
              <div style={{ height: "100vh" }}>
                <Router />
              </div>
            </QueryClientProvider>
          </AxiosProvider>
        </AuthProvider>
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
