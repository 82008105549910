import React, { createContext, useState } from "react";
import { UserVO } from "../../api/generated/v1.0/app";
import storageService from "../../utils/storage.service";

export const INITIAL_AUTH: Auth = {
  authorities: [],
  accessToken: "",
  refreshToken: "",
  tenantId: "",
  user: {},
};

export const AuthContext = createContext<{
  auth: Auth;
  setAuth: (auth: Partial<Auth>) => void;
  isLoggedIn: () => boolean;
}>({
  auth: INITIAL_AUTH,
  setAuth: (auth: Partial<Auth>) => {},
  isLoggedIn: () => false,
});

function AuthProvider({ children }: React.PropsWithChildren<unknown>) {
  const authFromLocalStorage = storageService.getAuth();
  const initialAuth = authFromLocalStorage != undefined
    ? authFromLocalStorage
    : INITIAL_AUTH;
  const [state, setState] = useState<Auth>(initialAuth);

  const setAuth = (auth: Partial<Auth>) => {
    storageService.updateAuth(auth);
    setState((state) => ({ ...state, ...auth }));
  };

  const isLoggedIn = () => {
    return (
      state.accessToken !== "" &&
      state.refreshToken !== "" &&
      state.user?.userId != undefined
    );
  };

  return (
    <AuthContext.Provider value={{ auth: state, setAuth, isLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;

// Types
export type Auth = {
  authorities: Authority[];
  accessToken: string;
  refreshToken: string;
  tenantId: string;
  user: UserVO;
};

export type Authority = "root" | "app";
