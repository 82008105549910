import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import Loading from "./components/Loading";

// import AdminLogin from "./admin/Login";
// import AdminHome from "./admin/Home";
// import ClientHome from "./client/Home";

const AdminLogin = lazy(() => import("./admin/Login"));
const AdminHome = lazy(() => import("./admin/Home"));
const ClientHome = lazy(() => import("./client/Home"));
const ClientLogin = lazy(() => import("./client/Login"));
const ConfirmEmail = lazy(() => import("./client/ConfirmEmail"));
const EmailVerificationSent = lazy(
  () => import("./client/EmailVerificationSent")
);
const ChangePassword = lazy(() => import("./client/ChangePassword"));
const ResetPasswordConfirmation = lazy(
  () => import("./client/ResetPasswordConfirmation")
);
const FirstTimeLogin = lazy(() => import("./client/FirstTimeLogin"));
const ForgotCompanyID = lazy(() => import("./client/ForgotCompanyID"));
const ResetCompanyID = lazy(() => import("./client/ResetCompanyID"));
const ForgotUserID = lazy(() => import("./client/ForgotUserID"));
const ResetUserID = lazy(() => import("./client/ResetUserID"));
const ForgotPassword = lazy(() => import("./client/ForgotPassword"));
const EmailOTP = lazy(() => import("./client/EmailOTP"));
const PasswordOTP = lazy(() => import("./client/PasswordOTP"));
const ResetPassword = lazy(() => import("./client/ResetPassword"));

export const GLOBAL_ROUTES: Record<GlobalRouteKeys, AppRoute> = {
  // Admin
  adminLogin: {
    path: "/admin/login",
    exact: true,
    component: AdminLogin,
    name: "Admin - Login",
  },
  adminHome: {
    path: "/admin",
    exact: false,
    component: AdminHome,
    name: "Admin - Home",
  },
  // Client
  clientLogin: {
    path: "/login",
    exact: true,
    component: ClientLogin,
    name: "Client - Login",
  },
  clientConfirmInformation: {
    path: "/confirm-information",
    exact: true,
    component: ConfirmEmail,
    name: "Client - Email Confirmation",
  },
  clientEmailVerificationSent: {
    path: "/email-verification-sent",
    exact: true,
    component: EmailVerificationSent,
    name: "Client - Email Confirmation Sent",
  },
  clientChangePassword: {
    path: "/change-password",
    exact: true,
    component: ChangePassword,
    name: "Client - Change Password",
  },
  clientResetPasswordConfirmation: {
    path: "/reset-password-confirmation",
    exact: true,
    component: ResetPasswordConfirmation,
    name: "Client - Reset Password",
  },
  clientFirstTimeLogin: {
    path: "/first-time-login",
    exact: true,
    component: FirstTimeLogin,
    name: "Client - First Time Login",
  },
  clientForgotCompanyId: {
    path: "/forgot-company-id",
    exact: true,
    component: ForgotCompanyID,
    name: "Client - Forgot Company ID",
  },
  clientResetCompanyId: {
    path: "/reset-company-id",
    exact: true,
    component: ResetCompanyID,
    name: "Client - Reset Company ID",
  },
  clientForgotUserId: {
    path: "/forgot-user-id",
    exact: true,
    component: ForgotUserID,
    name: "Client - Forgot User ID",
  },
  clientResetUserId: {
    path: "/reset-user-id",
    exact: true,
    component: ResetUserID,
    name: "Client - Reset User ID",
  },
  clientEmailOtp: {
    path: "/email-otp",
    exact: true,
    component: EmailOTP,
    name: "Client - Email OTP",
  },
  clientPasswordOtp: {
    path: "/password-otp",
    exact: true,
    component: PasswordOTP,
    name: "Client - Password OTP",
  },
  clientResetPassword: {
    path: "/reset-password",
    exact: true,
    component: ResetPassword,
    name: "Client - Reset Password",
  },
  clientForgotPassword: {
    path: "/forgot-password",
    exact: true,
    component: ForgotPassword,
    name: "Client - Forgot Password",
  },
  clientHome: {
    path: "",
    exact: false,
    component: ClientHome,
    name: "Client - Home",
  },
};

function Router() {
  const topLevelRoutes = Object.values(GLOBAL_ROUTES).map((route) => (
    <Route key={route.path} {...route} component={route.component} />
  ));
  return (
    <Suspense
      fallback={
        <div style={{ width: "100vw", height: "100vh" }}>
          <Loading size="large" />
        </div>
      }
    >
      <Switch>{topLevelRoutes}</Switch>
    </Suspense>
  );
}

export default Router;

// Types
export type GlobalRouteKeys =
  | "adminLogin"
  | "adminHome"
  // Client
  | "clientLogin"
  | "clientConfirmInformation"
  | "clientEmailVerificationSent"
  | "clientChangePassword"
  | "clientResetPasswordConfirmation"
  | "clientFirstTimeLogin"
  | "clientForgotCompanyId"
  | "clientResetCompanyId"
  | "clientForgotUserId"
  | "clientResetUserId"
  | "clientEmailOtp"
  | "clientPasswordOtp"
  | "clientResetPassword"
  | "clientForgotPassword"
  | "clientHome";
